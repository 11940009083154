import React from 'react'

const MiniLoader = () => {
	return (
		<div className="d-flex w-100 h-100">
			{/* <img className="loading-logo" src="/images/forms/wizard-logo.png" alt="" /> */}
			<div className="loader-main m-auto">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	)
}

export default MiniLoader
