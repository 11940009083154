import axios from "axios";

export const GET_SERVICES = 'GET_SERVICES';
export const GET_SERVICE_TYPES = 'GET_SERVICE_TYPES';
// const sampleResponse = {
// 	"logs": [
// 		{
// 			"id": 663734,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 2,
// 			"number_from": "17865229515",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": null,
// 			"forward_to": null,
// 			"start": 1717615159,
// 			"stop": 1717615162,
// 			"call_duration": 4,
// 			"call_sid": "c-aa90dc63-58a34e00-5194-4dd2-8129-06a0c0d292da",
// 			"call_status": "failed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-06-05 19:19:19",
// 			"updated_at": "2024-06-05 19:19:22",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null,
// 			"audio_path": "https://developtelco23.talk-local.com/play_audio/663734"
// 		},
// 		{
// 			"id": 663733,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 3,
// 			"number_from": "17865229515",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": null,
// 			"forward_to": "1 (786) 699-9486",
// 			"start": 1717614832,
// 			"stop": 1717614832,
// 			"call_duration": 1,
// 			"call_sid": "c-aa90dc63-aa7ad44a-3146-4f0d-886d-e7c27ac41b0c",
// 			"call_status": "failed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-06-05 19:13:52",
// 			"updated_at": "2024-06-05 19:13:53",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null
// 		},
// 		{
// 			"id": 663732,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 3,
// 			"number_from": "17865229515",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": null,
// 			"forward_to": "1 (786) 699-9486",
// 			"start": 1717614830,
// 			"stop": 1717614830,
// 			"call_duration": 1,
// 			"call_sid": "c-fef240ff-a53cad68-974a-4b62-9c4e-a141329377fb",
// 			"call_status": "failed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-06-05 19:13:50",
// 			"updated_at": "2024-06-05 19:13:51",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null
// 		},
// 		{
// 			"id": 663731,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 2,
// 			"number_from": "17865229515",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": null,
// 			"forward_to": null,
// 			"start": 1717614541,
// 			"stop": 1717614546,
// 			"call_duration": 5,
// 			"call_sid": "c-28bba681-ad63edbc-1c01-452c-9808-2f5051cd7852",
// 			"call_status": "failed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-06-05 19:09:01",
// 			"updated_at": "2024-06-05 19:09:07",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null,
// 			"audio_path": "https://developtelco23.talk-local.com/play_audio/663731"
// 		},
// 		{
// 			"id": 663730,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 2,
// 			"number_from": "17865229515",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": null,
// 			"forward_to": null,
// 			"start": 1717614493,
// 			"stop": 1717614498,
// 			"call_duration": 6,
// 			"call_sid": "c-d45a41e5-c07a9acb-9cc5-41c3-b8f9-548241d9fa2d",
// 			"call_status": "failed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-06-05 19:08:13",
// 			"updated_at": "2024-06-05 19:08:18",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null,
// 			"audio_path": "https://developtelco23.talk-local.com/play_audio/663730"
// 		},
// 		{
// 			"id": 663729,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 51,
// 			"number_from": "31524",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": "nahomgetu12@gmail.com",
// 			"forward_to": null,
// 			"start": 1717604968,
// 			"stop": null,
// 			"call_duration": null,
// 			"call_sid": "ed90cc2d-8648-42bb-86b9-92f34f58d2e1",
// 			"call_status": "completed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-06-05 16:29:28",
// 			"updated_at": "2024-06-05 16:29:28",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null
// 		},
// 		{
// 			"id": 663728,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 51,
// 			"number_from": "56468",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": "nahomgetu12@gmail.com",
// 			"forward_to": null,
// 			"start": 1717603920,
// 			"stop": null,
// 			"call_duration": null,
// 			"call_sid": "86aa6185-6612-47d0-ac3a-9706fe7dba04",
// 			"call_status": "completed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-06-05 16:12:00",
// 			"updated_at": "2024-06-05 16:12:00",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null
// 		},
// 		{
// 			"id": 663727,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 2,
// 			"number_from": "18666808628",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": null,
// 			"forward_to": null,
// 			"start": 1717600406,
// 			"stop": 1717600411,
// 			"call_duration": 6,
// 			"call_sid": "c-fef240ff-0c0961b2-d129-45d7-8710-37b92e23ae03",
// 			"call_status": "failed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-06-05 15:13:26",
// 			"updated_at": "2024-06-05 15:13:31",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null,
// 			"audio_path": "https://developtelco23.talk-local.com/play_audio/663727"
// 		},
// 		{
// 			"id": 663726,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 2,
// 			"number_from": "18666808628",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": null,
// 			"forward_to": null,
// 			"start": 1717593189,
// 			"stop": 1717593194,
// 			"call_duration": 6,
// 			"call_sid": "c-28bba681-506f9bba-893d-42c1-a4b7-51f9c74fa4ab",
// 			"call_status": "failed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-06-05 13:13:09",
// 			"updated_at": "2024-06-05 13:13:14",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null,
// 			"audio_path": "https://developtelco23.talk-local.com/play_audio/663726"
// 		},
// 		{
// 			"id": 663725,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 2,
// 			"number_from": "15415245228",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": null,
// 			"forward_to": null,
// 			"start": 1717545317,
// 			"stop": 1717545321,
// 			"call_duration": 5,
// 			"call_sid": "c-15ac29a2-246a6615-9467-4a1c-ae03-52ef31e85320",
// 			"call_status": "failed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-06-04 23:55:17",
// 			"updated_at": "2024-06-04 23:55:22",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null,
// 			"audio_path": "https://developtelco23.talk-local.com/play_audio/663725"
// 		},
// 		{
// 			"id": 663724,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 2,
// 			"number_from": "19729709530",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": null,
// 			"forward_to": null,
// 			"start": 1717543535,
// 			"stop": 1717543539,
// 			"call_duration": 5,
// 			"call_sid": "c-aa90dc63-733bedb1-be6e-4304-bdc6-bd47d7ac0c78",
// 			"call_status": "failed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-06-04 23:25:35",
// 			"updated_at": "2024-06-04 23:25:40",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null,
// 			"audio_path": "https://developtelco23.talk-local.com/play_audio/663724"
// 		},
// 		{
// 			"id": 663723,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 2,
// 			"number_from": "16035543345",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": null,
// 			"forward_to": null,
// 			"start": 1717542998,
// 			"stop": 1717543003,
// 			"call_duration": 6,
// 			"call_sid": "c-15ac29a2-0981073b-6334-433a-a5a4-351fcd93d62d",
// 			"call_status": "failed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-06-04 23:16:38",
// 			"updated_at": "2024-06-04 23:16:43",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null,
// 			"audio_path": "https://developtelco23.talk-local.com/play_audio/663723"
// 		},
// 		{
// 			"id": 663722,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 2,
// 			"number_from": "14159077128",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": null,
// 			"forward_to": null,
// 			"start": 1717540981,
// 			"stop": 1717540986,
// 			"call_duration": 6,
// 			"call_sid": "c-aa90dc63-8cc56d0e-3001-41b2-aa1e-ccffb2974f44",
// 			"call_status": "failed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-06-04 22:43:01",
// 			"updated_at": "2024-06-04 22:43:06",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null,
// 			"audio_path": "https://developtelco23.talk-local.com/play_audio/663722"
// 		},
// 		{
// 			"id": 663721,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 2,
// 			"number_from": "15619023959",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": null,
// 			"forward_to": null,
// 			"start": 1717537344,
// 			"stop": 1717537349,
// 			"call_duration": 6,
// 			"call_sid": "c-d45a41e5-f43f52e6-b920-4d1f-ad47-66581353e199",
// 			"call_status": "failed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-06-04 21:42:24",
// 			"updated_at": "2024-06-04 21:42:30",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null,
// 			"audio_path": "https://developtelco23.talk-local.com/play_audio/663721"
// 		},
// 		{
// 			"id": 663720,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 2,
// 			"number_from": "17377704449",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": null,
// 			"forward_to": null,
// 			"start": 1717461499,
// 			"stop": 1717461504,
// 			"call_duration": 5,
// 			"call_sid": "c-aa90dc63-6ec1f286-1baf-4856-b30e-6efbfb47cc43",
// 			"call_status": "failed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-06-04 00:38:19",
// 			"updated_at": "2024-06-04 00:38:25",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null,
// 			"audio_path": "https://developtelco23.talk-local.com/play_audio/663720"
// 		},
// 		{
// 			"id": 663719,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 2,
// 			"number_from": "13204042965",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": null,
// 			"forward_to": null,
// 			"start": 1717454482,
// 			"stop": 1717454487,
// 			"call_duration": 6,
// 			"call_sid": "c-d45a41e5-a0761505-bb33-4e66-91e0-2dba40d2d097",
// 			"call_status": "failed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-06-03 22:41:22",
// 			"updated_at": "2024-06-03 22:41:27",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null,
// 			"audio_path": "https://developtelco23.talk-local.com/play_audio/663719"
// 		},
// 		{
// 			"id": 663718,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 2,
// 			"number_from": "15137156072",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": null,
// 			"forward_to": null,
// 			"start": 1717446458,
// 			"stop": 1717446463,
// 			"call_duration": 6,
// 			"call_sid": "c-aa90dc63-b5c64056-d91c-45e1-b76a-7bb09ce914dc",
// 			"call_status": "failed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-06-03 20:27:38",
// 			"updated_at": "2024-06-03 20:27:43",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null,
// 			"audio_path": "https://developtelco23.talk-local.com/play_audio/663718"
// 		},
// 		{
// 			"id": 663717,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 2,
// 			"number_from": "14066862761",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": null,
// 			"forward_to": null,
// 			"start": 1717273022,
// 			"stop": 1717273028,
// 			"call_duration": 7,
// 			"call_sid": "c-28bba681-6e51085d-0b4f-48ba-8f52-31f49ae8f3c2",
// 			"call_status": "failed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-06-01 20:17:02",
// 			"updated_at": "2024-06-01 20:17:09",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null,
// 			"audio_path": "https://developtelco23.talk-local.com/play_audio/663717"
// 		},
// 		{
// 			"id": 663716,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 2,
// 			"number_from": "18125457889",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": null,
// 			"forward_to": null,
// 			"start": 1717261625,
// 			"stop": 1717261630,
// 			"call_duration": 6,
// 			"call_sid": "c-93d6f3c0-005de62c-1eee-48ec-867c-0edad6952439",
// 			"call_status": "failed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-06-01 17:07:05",
// 			"updated_at": "2024-06-01 17:07:11",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null,
// 			"audio_path": "https://developtelco23.talk-local.com/play_audio/663716"
// 		},
// 		{
// 			"id": 663715,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 2,
// 			"number_from": "18164023602",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": null,
// 			"forward_to": null,
// 			"start": 1717201927,
// 			"stop": 1717201939,
// 			"call_duration": 13,
// 			"call_sid": "c-d45a41e5-b03403db-4940-45e6-8eb4-f9dd9b30172e",
// 			"call_status": "failed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-06-01 00:32:07",
// 			"updated_at": "2024-06-01 00:32:19",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null,
// 			"audio_path": "https://developtelco23.talk-local.com/play_audio/663715"
// 		},
// 		{
// 			"id": 663713,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 51,
// 			"number_from": "31524",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": "nahomgetu12@gmail.com",
// 			"forward_to": null,
// 			"start": 1717192281,
// 			"stop": null,
// 			"call_duration": null,
// 			"call_sid": "fcca2488-7f40-4b0e-8530-eb0895158fe6",
// 			"call_status": "completed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-05-31 21:51:21",
// 			"updated_at": "2024-05-31 21:51:21",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null
// 		},
// 		{
// 			"id": 663712,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 2,
// 			"number_from": "18164023461",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": null,
// 			"forward_to": null,
// 			"start": 1717106151,
// 			"stop": 1717106156,
// 			"call_duration": 6,
// 			"call_sid": "c-27ed0ce9-ce581fad-c947-4c7b-8111-f4028f9f5de8",
// 			"call_status": "failed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-05-30 21:55:51",
// 			"updated_at": "2024-05-30 21:55:56",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null,
// 			"audio_path": "https://developtelco23.talk-local.com/play_audio/663712"
// 		},
// 		{
// 			"id": 663711,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 2,
// 			"number_from": "18163008538",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": null,
// 			"forward_to": null,
// 			"start": 1717092700,
// 			"stop": 1717092705,
// 			"call_duration": 6,
// 			"call_sid": "c-52850c03-6ae546f2-ae87-4a89-b96d-c8a7c9cc6e42",
// 			"call_status": "failed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-05-30 18:11:40",
// 			"updated_at": "2024-05-30 18:11:45",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null,
// 			"audio_path": "https://developtelco23.talk-local.com/play_audio/663711"
// 		},
// 		{
// 			"id": 663710,
// 			"ast_id": null,
// 			"number_id": 14631,
// 			"category_id": 2,
// 			"number_from": "18164770536",
// 			"name_from": null,
// 			"sms_to": null,
// 			"mms_to": null,
// 			"email_to": null,
// 			"forward_to": null,
// 			"start": 1717091238,
// 			"stop": 1717091243,
// 			"call_duration": 6,
// 			"call_sid": "c-fe23a767-e7ce7f5b-95d5-42da-a4dc-9b409e8edc1a",
// 			"call_status": "failed",
// 			"direction": "inbound",
// 			"caller_country": null,
// 			"caller_state": null,
// 			"caller_city": null,
// 			"container": null,
// 			"compartment": null,
// 			"media_file": null,
// 			"recording_duration": null,
// 			"recording_url": null,
// 			"vm_stat": "unread",
// 			"soft_delete_recording": 0,
// 			"created_at": "2024-05-30 17:47:18",
// 			"updated_at": "2024-05-30 17:47:23",
// 			"recording_format": null,
// 			"vendor_id": 13,
// 			"recording_transcription_decode": null,
// 			"audio_path": "https://developtelco23.talk-local.com/play_audio/663710"
// 		}
// 	],
// 	"cust_info": [
// 		{
// 			"first_name": "William Ross",
// 			"last_name": "Jackson",
// 			"gmt_zone": "GMT-08:00",
// 			"on_id": 14631
// 		}
// 	]
// }

export const getServices = (id, params = {}) => async (dispatch) => {

	let config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${process.env.REACT_APP_TELECOM_URL}/api/v1/cust_log/all/${id}`,
		params: params,
		headers: {
			'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
		}
	};

	axios.request(config)
		.then((response) => {
			dispatch({
				type: GET_SERVICES,
				payload: response.data?.result
				// payload: sampleResponse.result
			});
		})
		.catch((error) => {
			console.log(error);
		});
}
export const getServiceTypes = () => async (dispatch) => {

	let config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${process.env.REACT_APP_API_URL}/api/my-services/get-types`,
		headers: {
			'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
		}
	};

	axios.request(config)
		.then((response) => {
			dispatch({
				type: GET_SERVICE_TYPES,
				payload: response.data.result
			});
		})
		.catch((error) => {
			console.log(error);
		});
}

export const softDeleteService = (id) => async () => {
	return axios.delete(`my-services/delete-log/${id}`).then(resp => {
		return resp.data;
	})
}
